import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import GoogleButton from 'react-google-button';
import {
  Row, StyledSectionHeader, StyledButton, StyledButtonText,
} from '../../components/Layout';
import MerchantLayout from '../../components/Layout/MerchantLayout';
import HappyHeart from '../../images/happyHeart.svg';
import merchants from '../../merchants.json';
import MerchantLink from '../../components/MerchantLink';
import { apiUpdate } from '../../services/sendToApi';

const StyledHappyHeart = styled(HappyHeart)`
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;  
`;

const StyledHappySmallHeart = styled(HappyHeart)`
  margin-top: 5px;
  margin-bottom: 25px;
  width: 50%;  
`;

const StyledMerchantLogo = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 60%;
`;

const StyledText = styled.p`
  margin: 20px 0px 20px 0px;
  font-size: 18px;
`;

const StyledA = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
`;

function SimpleHappyMerchant({ title, websiteUrl, logoUrl }) {
  return (
    <>
      <Row>
        <StyledSectionHeader>
          {title}
        </StyledSectionHeader>
      </Row>
      <StyledHappyHeart />
      <MerchantLink websiteUrl={websiteUrl} />
      <StyledMerchantLogo src={logoUrl} />
    </>
  );
}

function CustomHappyMerchant({ text, websiteUrl, voucherCode }) {
  return (
    <>
      <Row>
        <StyledSectionHeader>
          {text[0]}
        </StyledSectionHeader>
      </Row>
      <StyledHappySmallHeart />
      <Row>
        <StyledText>
          {text[1]}
&nbsp;
          <b>{voucherCode}</b>
        </StyledText>
      </Row>
      <Row>
        <StyledA target="_blank" rel="noreferrer" href={websiteUrl}>
          <StyledButton>
            <StyledButtonText>
              {text[2]}
            </StyledButtonText>
          </StyledButton>
        </StyledA>
      </Row>
    </>
  );
}

function GoogleReviewMerchant({
  title, text, reviewUrl, websiteUrl, voucherCode,
}) {
  return (
    <>
      <Row>
        <StyledSectionHeader>
          {title}
        </StyledSectionHeader>
      </Row>
      <Row>
        <StyledText>
          {text[0]}
        </StyledText>
      </Row>
      <StyledHappySmallHeart />
      <Row>
        <StyledA target="_blank" rel="noreferrer" href={reviewUrl}>
          <GoogleButton
            label={text[1]}
          />
        </StyledA>
      </Row>
      <Row>
        <StyledText>
          {text[2]}
          &nbsp;
          <b>{voucherCode}</b>
        </StyledText>
      </Row>
      <Row>
        <StyledA target="_blank" rel="noreferrer" href={websiteUrl}>
          <StyledButton>
            <StyledButtonText>
              {text[3]}
            </StyledButtonText>
          </StyledButton>
        </StyledA>
      </Row>
    </>
  );
}

function IndexPage({ location }) {
  const { t, i18n } = useTranslation();
  const { state = {} } = location;
  const { id, merchant } = state;

  useEffect(() => {
    apiUpdate(id, { mood: 'happy' });
  }, []);

  const merchantConfig = merchants[merchant] || {};
  const {
    logoUrls = {}, websiteUrl = '', customHappy, voucherCode,
  } = merchantConfig;
  const customHappyText = customHappy ? customHappy.text[i18n.language] : undefined;

  const getContent = () => {
    if (!voucherCode) {
      return (
        <SimpleHappyMerchant
          title={t('merchant.happy.title')}
          websiteUrl={websiteUrl}
          logoUrl={logoUrls.black}
        />
      );
    }

    if (!customHappy.reviewUrl) {
      return (
        <CustomHappyMerchant
          text={customHappyText}
          websiteUrl={websiteUrl}
          voucherCode={voucherCode}
        />
      );
    }

    return (
      <GoogleReviewMerchant
        title={t('merchant.happy.title')}
        text={customHappyText}
        reviewUrl={customHappy.reviewUrl}
        websiteUrl={websiteUrl}
        voucherCode={voucherCode}
      />
    );
  };

  return (
    <MerchantLayout merchant={merchant}>
      {getContent()}
    </MerchantLayout>
  );
}

export default IndexPage;
