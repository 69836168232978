import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Row, StyledDisclaimerText,
} from './Layout';

const StyledLink = styled.a`
  text-decoration: none;
  font-family: "Sombra Bold";
  color: #000000;
  
  :visited,:hover,:active {
    text-decoration: none;
    color: #000000;
  }
}
`;

function MerchantLink({ websiteUrl }) {
  const { t } = useTranslation();

  return (
    <Row>
      <StyledDisclaimerText>
        {t('merchant.merchantLink.0')}
        <br />
        <StyledLink href={websiteUrl} target="_blank" rel="noreferrer">{t('merchant.merchantLink.1')}</StyledLink>
        {' '}
        {t('merchant.merchantLink.2')}
      </StyledDisclaimerText>
    </Row>
  );
}

export default MerchantLink;
